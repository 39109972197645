import { Box, Button, Card, Divider, Skeleton, Stack, Typography } from "@mui/material"
import { AdoptClient, FlushGraphQLCache } from "event-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import { If } from "lib/@components/switch"
import { User } from "lib/user/user"
import useCollapseDrawer from "minimals-template/components/@hooks/useCollapseDrawer"
import Iconify from "minimals-template/components/Iconify"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { getSharingLink } from "routes/sharing/controller/get-sharing-link"
import { useCurrentUser } from "lib/store/use-current-user"
import { downloadRegime } from "routes/regime/pdf/controller/download-regime-pdf"
import { DownloadPDFButton } from "routes/pdf/download-pdf-button"
import { DATE_FORMATS } from "lib/@constants/date-format"
import { relinquishAdoptedClient } from "routes/sfg20-admin/plugins/clients-tab/controller/relinquish-adopted-client.api"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { Bound } from "lib/@components/binding/Bound"
import { ShareLinkActions, ShareLinkCloseLinkRow } from "slot-definitions"
import { updateAdoptClientRestrictionAdopt } from "routes/client-admin/plugins/adopt-client-restriction/controller/update-adopt-client-restriction-adopt"
import { uniqueId } from "library/database/split-id"
import { getAdoptClientRestriction } from "routes/client-admin/plugins/adopt-client-restriction/controller/get-adopt-client-restriction"
import { useInterval } from "lib/@hooks/useInterval"

import { busyWhile } from "lib/@components/busy/busy-while"
import { FEATURE_FLAG_DOWNLOAD_BOOKLET } from "routes/regime/plugins/download-booklet/download-booklet.runtime"
import { Feature } from "lib/@components/feature"

export function WhoAmI() {
    const { isCollapse } = useCollapseDrawer()
    useLocation()

    const user = useCurrentUser()
    const { share } = Object.fromEntries(new URLSearchParams(window.location.search).entries())
    const link = getSharingLink.useResults(share)
    const [visible, setVisible] = useState(false)

    return (
        <Bound shareLink={link}>
            <Box sx={{ minWidth: !isCollapse ? 250 : 0 }}>
                <Card
                    sx={{
                        mx: 2,
                        mb: 1,

                        bgcolor: isCollapse ? "transparent !important" : undefined,
                        boxShadow: isCollapse ? "none !important" : undefined,
                        borderColor: isCollapse ? "transparent !important" : undefined,
                    }}
                    variant="outlined"
                >
                    <If truthy={!isCollapse}>
                        {!visible && (
                            <>
                                <ListItemBox p={1} spacing={1}>
                                    <Skeleton variant="circular" width={42} height={42} />
                                    <Skeleton animation="wave" variant="rounded" width={100} height={42} />
                                </ListItemBox>
                            </>
                        )}
                        {share && !!link ? (
                            <Box sx={{ display: visible ? "block" : "none", width: "100%" }}>
                                <Box p={1}>
                                    <User rendered={setVisible} id={link.creator} allowClientSwitching />
                                </Box>
                                <Divider />
                                <ListItemBox spacing={2} sx={{ p: 2 }}>
                                    <Iconify icon="mdi:link-variant" sx={{ fontSize: 24, color: "primary.main" }} />
                                    <Typography component="div" sx={{ flex: 1 }} variant="body1">
                                        {link.name}
                                    </Typography>
                                </ListItemBox>
                                <Box px={2} mt={-2} mb={1} pl={7}>
                                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                        until {new Date(link.endDate).format(DATE_FORMATS.medium)}
                                    </Typography>
                                </Box>
                                <Divider />
                                <Stack mt={2} width={1} spacing={0.5}>
                                    <ShareLinkActions.Slot>
                                        {!!link.canDownloadPDF && (
                                            <Feature feature={FEATURE_FLAG_DOWNLOAD_BOOKLET}>
                                                <DownloadPDFButton
                                                    onDownload={() =>
                                                        handleRegimeDownload(link.regime, encodeURIComponent(share))
                                                    }
                                                    filename="Regime"
                                                />
                                            </Feature>
                                        )}

                                        <Stack direction="row">
                                            <ShareLinkCloseLinkRow.Slot>
                                                <Button
                                                    onClick={clear}
                                                    fullWidth
                                                    size="small"
                                                    startIcon={<Iconify icon="mdi:clear-circle-outline" />}
                                                >
                                                    Close Link
                                                </Button>
                                            </ShareLinkCloseLinkRow.Slot>
                                        </Stack>
                                    </ShareLinkActions.Slot>
                                </Stack>
                            </Box>
                        ) : (
                            <Box p={1} sx={{ display: visible ? "block" : "none" }}>
                                <User sx={{ maxWidth: 230 }} id={user.id} rendered={setVisible} allowClientSwitching />
                            </Box>
                        )}
                    </If>
                    <If truthy={isCollapse}>
                        {!visible && (
                            <>
                                <ListItemBox p={1} spacing={1}>
                                    <Skeleton variant="circular" width={42} height={42} />
                                </ListItemBox>
                            </>
                        )}
                        {share && !!link ? (
                            <Box sx={{ display: visible ? "block" : "none" }}>
                                <Box px={0.5} py={2.25}>
                                    <User
                                        sx={{ maxWidth: 230 }}
                                        rendered={setVisible}
                                        id={link.creator}
                                        hideDetails
                                        allowClientSwitching
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box px={0.5} sx={{ display: visible ? "block" : "none" }} py={1.25}>
                                <User
                                    sx={{ maxWidth: 230 }}
                                    id={user.id}
                                    rendered={setVisible}
                                    hideDetails
                                    allowClientSwitching
                                />
                            </Box>
                        )}
                    </If>
                    <If truthy={user.isOverridingClient}>
                        <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
                            <UnadoptClientButton user={user} />
                        </Box>
                    </If>
                </Card>
            </Box>
        </Bound>
    )

    function clear() {
        FlushGraphQLCache.raise()
        AdoptClient.raise(undefined)
        window.location.href = `${window.location.protocol}//${window.location.host}/app`
    }

    async function handleRegimeDownload(regimeId, shareId) {
        return downloadRegime(regimeId, shareId, user.displayName)
    }
}

function UnadoptClientButton({ user }) {
    const adoptClientRestriction = getAdoptClientRestriction.useResults(user.client)

    useEffect(() => {
        if (adoptClientRestriction) {
            checkSessionEnded()
        }
    })

    useInterval(checkSessionEnded, 30000)

    return (
        <Button
            onClick={unadoptClient}
            color="primary"
            variant="contained"
            size="small"
            data-cy="unadopt-client-button"
        >
            <Box>Unadopt Client</Box>
        </Button>
    )

    async function checkSessionEnded() {
        if (new Date() > new Date(adoptClientRestriction.end)) {
            await busyWhile(async () => {
                await unadoptClient()
            }, "Unadopting client")
        }
    }

    async function unadoptClient() {
        await updateAdoptClientRestrictionAdopt({ clientId: uniqueId(user.client), adopt: false })
        const response = await relinquishAdoptedClient()
        if (!response.status) {
            errorSnackbar(response.message)
        }
    }
}
